import React from "react";

import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import TitleC6 from "../../components/Typography/TitleC6";
import FormManual from "./FormManual";
import requests from "../../services/requests";

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Processo Manual" />
            <div className="p-4">
                <Card>
                    <TitleC6>Importar Planilha de Processos</TitleC6>

                    <div className="border-t border-C2 border-solid pt-4 pb-12 overflow-hidden">
                        <div className="flex mt-5">
                            <div className="w-full">
                                <h6 className="italic font-light">
                                    <b>Faça o download</b> da planilha atual{" "}
                                    <a
                                        href={requests.getApiUrl() + `/processo-manual.xlsm`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="underline text-blue-600"
                                        download
                                    >
                                        clicando aqui
                                    </a>
                                    .
                                </h6>
                                <h6 className="italic font-light">
                                    <b>Preencha as informações</b>, e fique atento aos campos obrigatórios.
                                </h6>

                                <h5 className="mt-10">Importe a planilha</h5>
                                <FormManual />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default IndexPage;
